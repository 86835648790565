<template>
  <div class="header-bg">
    <span class="bgCenter-word1"></span>
    <span class="bgCenter-word2"></span>
    <span class="bgCenter-word3"></span>
    <span class="bgCenter-word4"></span>
    <a href="javascript:void(0)" class="bottomBtn" @click="showDialog(true)"
    >立即体验</a>
    <application-pop
      sysName="知识中台"
      sysCode="08"
      v-model="dialogVisible"
      @update:modelValue="showDialog($event)"
    ></application-pop>
  </div>
</template>

<script setup>
import ApplicationPop from '@/components/ApplicationPop/index.vue'
import { ref } from 'vue'
const dialogVisible = ref(false)
const showDialog = (value) => {
  dialogVisible.value = value
}
</script>

<style scoped lang="less">
.header-bg {
  width: 100%;
  height: 679px;
  background: url('~@/assets/images/knowledgeCenter/bgCenter.png') no-repeat center;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  .bgCenter-word1 {
    width: 320px;
    height: 70px;
    background: url('~@/assets/images/knowledgeCenter/bgCenter-word1.png') no-repeat center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 344px;
  }
  .bgCenter-word2 {
    width: 178px;
    height: 70px;
    background: url('~@/assets/images/knowledgeCenter/bgCenter-word2.png') no-repeat center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 450px;
    margin-left: -276px;
    animation: movepoint2 2s infinite;
    -webkit-animation: movepoint2 2s infinite;
  }
  .bgCenter-word3 {
    width: 178px;
    height: 70px;
    background: url('~@/assets/images/knowledgeCenter/bgCenter-word3.png') no-repeat center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 508px;
    margin-left: -74px;
    animation: movepoint1 2s infinite;
    -webkit-animation: movepoint1 2s infinite;
  }
  .bgCenter-word4 {
    width: 178px;
    height: 70px;
    background: url('~@/assets/images/knowledgeCenter/bgCenter-word4.png') no-repeat center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 440px;
    margin-left: 126px;
    animation: movepoint2 2s infinite;
    -webkit-animation: movepoint2 2s infinite;
  }
  .bottomBtn {
    padding: 20px 50px;
    height: 65px;
    background: linear-gradient(88deg, #2975F4 0%, #32A8FC 100%);
    box-shadow: -2px 7px 32px 0px rgba(0,0,0,0.28);
    border-radius: 33px;
    font-size: 26px;
    color: #ffffff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 200px;
  }
}
@keyframes movepoint1 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes movepoint2 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
