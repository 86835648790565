<template>
  <ul class="part2-grid">
    <li v-for="(item, index) in innerData" :key="index">
      <img :src="item.img" alt="">
      <span>{{item.name}}</span>
    </li>
  </ul>
</template>

<script setup>
import { ref } from 'vue'
const imgs = [
  { img: 'part2-1.png', name: '要素提取' },
  { img: 'part2-2.png', name: '概要生成' },
  { img: 'part2-3.png', name: '敏感词识别' },
  { img: 'part2-4.png', name: '文本分类' },
  { img: 'part2-5.png', name: '相似度计算' },
  { img: 'part2-6.png', name: 'OCR识别' },
  { img: 'part2-7.png', name: '实体抽取' },
  { img: 'part2-8.png', name: '事件抽取' },
  { img: 'part2-9.png', name: '案例推荐' },
  { img: 'part2-10.png', name: '案由识别' },
  { img: 'part2-11.png', name: '印章检测' },
  { img: 'part2-12.png', name: '签字检测' }
]
const innerData = ref([])
imgs.forEach((item, index) => {
  innerData.value.push({ img: require('@/assets/images/knowledgeCenter/' + item.img), name: item.name })
})
</script>

<style scoped lang="less">
.part2-grid {
  height: 550px;
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  background: #fff;
  li {
    text-align: center;
    transition: .2s;
    img {
      -webkit-user-drag: none;
    }
    span {
      font-size: 20px;
      color: #25282B;
      font-weight: bold;
      margin-top: 28px;
      line-height: 60px;
    }
  }
  li:hover {
    scale: 1.1;
  }
}
</style>
