import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-568b0280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-main" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Swiper"], {
      class: "part5-ul",
      modules: $setup.modules,
      slidesPerView: 3,
      slidesPerGroup: 3,
      pagination: { clickable: true },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.IData, (item, index) => {
          return _createVNode($setup["SwiperSlide"], { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: require('@/assets/images/knowledgeCenter/' + item.img),
                  alt: ""
                }, null, 8 /* PROPS */, _hoisted_3),
                _createElementVNode("h2", {
                  innerHTML: item.name
                }, null, 8 /* PROPS */, _hoisted_4)
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        }), 64 /* STABLE_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    })
  ]))
}