<template>
  <ul class="part3-grid">
    <li v-for="(item, index) in innerData" :key="index">
      <img :src="item.img" alt="">
    </li>
  </ul>
</template>

<script setup>
import { ref } from 'vue'
const imgs = [
  'part3-1.png',
  'part3-2.png',
  'part3-3.png'
]
const innerData = ref([])
imgs.forEach((item, index) => {
  innerData.value.push({ img: require('@/assets/images/knowledgeCenter/' + item) })
})
</script>

<style scoped lang="less">
.part3-grid {
  height: 250px;
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  background: #fff;
  li {
    transition: .2s;
    img {
      -webkit-user-drag: none;
    }
  }
  li:hover {
    margin-top: -8px;
  }
}
</style>
