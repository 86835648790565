<template>
  <div class="common-cont">
    <part-1></part-1>
    <com-box title="产品简介">
      <part-2></part-2>
    </com-box>
    <com-box title="产品亮点">
      <part-3></part-3>
    </com-box>
    <com-box title="产品架构">
      <part-4></part-4>
    </com-box>
    <com-box title="典型案例" class="part5">
      <part-5></part-5>
    </com-box>
  </div>
</template>

<script setup>
import ComBox from '@/components/comBox/wComBox.vue'
import Part1 from './cpn/part1'
import Part2 from './cpn/part2'
import Part3 from './cpn/part3'
import Part4 from './cpn/part4'
import Part5 from './cpn/part5'

</script>

<style scoped lang="less">
.common-cont {
  background: #fff;
}
.part5 {
  width: 1200px;
  margin:auto;
}
</style>
