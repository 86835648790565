<template>
  <div class="part4">
    <img :src="require('@/assets/images/knowledgeCenter/centerData.png')" class="part4-img" alt="">
  </div>
</template>

<script setup>

</script>

<style scoped>
.part4-img {
  width: 1200px;
  -webkit-user-drag: none;
}
</style>
